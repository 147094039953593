import { useRef } from 'react';

import styles from './App.module.scss';
import config from './config';

const { dashboard } = config;

const App = () => {
  const iframeRef = useRef<HTMLIFrameElement>() as any;

  return (
    <iframe
      src={`${dashboard}/leads`}
      ref={iframeRef}
      id={styles.app}
      title={'Manage Leads'}
    />
  );
};

export default App;
